// Here you can add other styles
.cardColor {
  background-color: indianred;
}

.idCard {
  width: 450px;
  height: 530px;
}

.idCardCenter {

  text-align: center;
}

.tableCss {
  height: 1000px;
}

